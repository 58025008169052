<template>
    <basic-page class="privacy-policy" :pagedata="this.pageData" bannerimg="/img/banner-policy.jpg">
            <div class="container-beige intro container" v-if="pageData.attributes.body!=undefined" v-html="this.pageData.attributes.body.processed"></div>
    </basic-page>
</template>

<script>
import BasicPage from './BasicPage.vue'
import {
    fetchSinglePage
} from '../libs/drupalClient'
export default {
  components: { BasicPage },
    name: 'privacy-policy',
        data: () => {
            return {
                currentPageId: "e856adf2-37d1-4459-aa81-d578a38b0e81",
                pageData: {
                    attributes: {}
                }
            }
        },
        mounted() {
            fetchSinglePage(this.currentPageId, this.lang)
                .then(res => {
                    this.pageData = res[0]
                    //console.log(this.pageData)
                })
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/colors.scss";

</style>